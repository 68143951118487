import React, { Component } from "react";

import FeatherIcon from "../../../modules/icons/featherreact";

import context from "../../../context";

import sitetranslations from "../SiteTranslations";


const appContext = context.application;

class BackCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      environment: appContext.getEnvironment(),
    };
    this.updateStateFromEnvironment =
      this.updateStateFromEnvironment.bind(this);
  }

  updateStateFromEnvironment() {
    this.setState({
      environment: appContext.getEnvironment(),
    });
  }

  updateActionSubscription = null;
  updateEnvSubscription = null;

  componentDidMount() {
    console.log("Init back button");
    const that = this;
    that.UpdateAction = () => {
      that.forceUpdate();
    };
    this.updateActionSubscription = appContext.onURLChanged(that.UpdateAction);
    this.updateEnvSubscription = appContext.onEnvironmentChanged(that.updateStateFromEnvironment);
  }

  componentWillUnmount() {
    const that = this;
    clearInterval(that.useLinkInterval);
    if (this.updateActionSubscription) {
      this.updateActionSubscription.unsubscribe();
    }
    if (this.updateEnvSubscription) {
      this.updateEnvSubscription.unsubscribe();
    }
  }

  render() {
    if (
      appContext.hasReturnUrl() &&
      (this.state.environment === "settings" ||
        this.state.environment === "authentication")
    ) {
      const returnUrl = `/app/return${appContext.getReturnUrl()}`;
      return (
        <div className="nav-item dropdown">
          <a href={returnUrl} className="btn btn-outline-secondary">
            <FeatherIcon
              icon="arrow-left"
              options={{ width: 20, height: 20 }}
            />
            <span className="d-none d-sm-inline">
              {sitetranslations.t("HEADER_GO_BACK_BUTTON")}
            </span>
          </a>
        </div>
      );
    }
    return null;
  }
}

export default BackCard;
