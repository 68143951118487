// @generated by protobuf-ts 2.9.4 with parameter server_none
// @generated from protobuf file "entitiesproto/entities.proto" (package "Entities", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Entities.Entity
 */
export interface Entity {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: Entities.EntityStatus status = 3;
     */
    status: EntityStatus;
    /**
     * @generated from protobuf field: Entities.EntityType type = 4;
     */
    type: EntityType;
    /**
     * @generated from protobuf field: int64 created_at = 5;
     */
    createdAt: bigint;
}
/**
 * @generated from protobuf message Entities.EntityProperty
 */
export interface EntityProperty {
    /**
     * @generated from protobuf field: string entity_id = 1;
     */
    entityId: string;
    /**
     * @generated from protobuf field: string scope = 2;
     */
    scope: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 4;
     */
    value: string;
}
/**
 * @generated from protobuf message Entities.Person
 */
export interface Person {
    /**
     * @generated from protobuf field: string entity_id = 1;
     */
    entityId: string;
    /**
     * @generated from protobuf field: string shortname = 2;
     */
    shortname: string;
    /**
     * @generated from protobuf field: int64 birthday = 3;
     */
    birthday: bigint;
}
/**
 * @generated from protobuf message Entities.WebUser
 */
export interface WebUser {
    /**
     * @generated from protobuf field: string entity_id = 1;
     */
    entityId: string;
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string;
    /**
     * @generated from protobuf field: string password_encoded = 3;
     */
    passwordEncoded: string;
    /**
     * @generated from protobuf field: int64 password_changed_on = 4;
     */
    passwordChangedOn: bigint;
    /**
     * @generated from protobuf field: string lang = 5;
     */
    lang: string;
    /**
     * @generated from protobuf field: int64 otp_type = 6;
     */
    otpType: bigint;
    /**
     * @generated from protobuf field: string otp_secret = 7;
     */
    otpSecret: string;
    /**
     * @generated from protobuf field: int64 otp_added_at = 8;
     */
    otpAddedAt: bigint;
    /**
     * @generated from protobuf field: bool webauthn_certificates_added = 9;
     */
    webauthnCertificatesAdded: boolean;
}
/**
 * @generated from protobuf message Entities.Email
 */
export interface Email {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: string entity_id = 2;
     */
    entityId: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: bool valid = 4;
     */
    valid: boolean;
    /**
     * @generated from protobuf field: int64 validated_at = 5;
     */
    validatedAt: bigint;
    /**
     * @generated from protobuf field: bool primary = 6;
     */
    primary: boolean;
    /**
     * @generated from protobuf field: int64 primary_at = 7;
     */
    primaryAt: bigint;
    /**
     * @generated from protobuf field: int64 created_at = 8;
     */
    createdAt: bigint;
}
/**
 * @generated from protobuf message Entities.User
 */
export interface User {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: Entities.Entity entity = 2;
     */
    entity?: Entity;
    /**
     * @generated from protobuf field: Entities.Person person = 3;
     */
    person?: Person;
    /**
     * @generated from protobuf field: Entities.WebUser webuser = 4;
     */
    webuser?: WebUser;
    /**
     * @generated from protobuf field: repeated Entities.Email emails = 5;
     */
    emails: Email[];
    /**
     * @generated from protobuf field: Entities.Email primary_email = 6;
     */
    primaryEmail?: Email;
    /**
     * @generated from protobuf field: repeated Entities.EntityProperty properties = 7;
     */
    properties: EntityProperty[];
}
/**
 * @generated from protobuf message Entities.Organization
 */
export interface Organization {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: Entities.Entity entity = 2;
     */
    entity?: Entity;
    /**
     * @generated from protobuf field: string slug = 3;
     */
    slug: string;
    /**
     * @generated from protobuf field: string headquarters = 4;
     */
    headquarters: string;
    /**
     * @generated from protobuf field: repeated Entities.OrganizationRole roles = 6;
     */
    roles: OrganizationRole[];
    /**
     * @generated from protobuf field: repeated Entities.EntityProperty properties = 7;
     */
    properties: EntityProperty[];
}
/**
 * @generated from protobuf message Entities.OrganizationRole
 */
export interface OrganizationRole {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string organization_id = 2;
     */
    organizationId: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string comment = 4;
     */
    comment: string;
    /**
     * @generated from protobuf field: bool active = 5;
     */
    active: boolean;
}
/**
 * @generated from protobuf message Entities.RolePermissionMap
 */
export interface RolePermissionMap {
    /**
     * @generated from protobuf field: bool owner = 1;
     */
    owner: boolean;
    /**
     * @generated from protobuf field: bool master = 2;
     */
    master: boolean;
    /**
     * @generated from protobuf field: bool operator = 3;
     */
    operator: boolean;
    /**
     * @generated from protobuf field: bool undelete = 4;
     */
    undelete: boolean;
    /**
     * @generated from protobuf field: bool delete = 5;
     */
    delete: boolean;
    /**
     * @generated from protobuf field: bool create = 6;
     */
    create: boolean;
    /**
     * @generated from protobuf field: bool edit = 7;
     */
    edit: boolean;
    /**
     * @generated from protobuf field: bool view = 8;
     */
    view: boolean;
}
/**
 * @generated from protobuf message Entities.OrganizationRoleResource
 */
export interface OrganizationRoleResource {
    /**
     * @generated from protobuf field: string role_id = 1;
     */
    roleId: string;
    /**
     * @generated from protobuf field: string resource = 2;
     */
    resource: string;
    /**
     * @generated from protobuf field: Entities.RolePermissionMap permission_map = 3;
     */
    permissionMap?: RolePermissionMap;
}
/**
 * @generated from protobuf message Entities.ApplicationDomain
 */
export interface ApplicationDomain {
    /**
     * @generated from protobuf field: string domain = 1;
     */
    domain: string;
    /**
     * @generated from protobuf field: int64 validated_on = 2;
     */
    validatedOn: bigint;
}
/**
 * @generated from protobuf message Entities.Application
 */
export interface Application {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: Entities.Entity entity = 2;
     */
    entity?: Entity;
    /**
     * @generated from protobuf field: string key = 3;
     */
    key: string;
    /**
     * @generated from protobuf field: string return_url = 4;
     */
    returnUrl: string;
    /**
     * @generated from protobuf field: repeated Entities.ApplicationDomain domains = 5;
     */
    domains: ApplicationDomain[];
    /**
     * @generated from protobuf field: repeated Entities.EntityProperty properties = 7;
     */
    properties: EntityProperty[];
}
/**
 * @generated from protobuf message Entities.RolePermissionMapByUserResourcesOrganizationRequest
 */
export interface RolePermissionMapByUserResourcesOrganizationRequest {
    /**
     * @generated from protobuf field: Entities.Organization organization = 1;
     */
    organization?: Organization;
    /**
     * @generated from protobuf field: Entities.User user = 2;
     */
    user?: User;
    /**
     * @generated from protobuf field: repeated Entities.OrganizationRoleResource role_resources = 3;
     */
    roleResources: OrganizationRoleResource[];
}
/**
 * @generated from protobuf message Entities.RolePermissionMapByUserResourcesOrganizationResponse
 */
export interface RolePermissionMapByUserResourcesOrganizationResponse {
    /**
     * @generated from protobuf field: repeated Entities.OrganizationRoleResource role_resources = 1;
     */
    roleResources: OrganizationRoleResource[];
}
/**
 * @generated from protobuf message Entities.UserInRoleNameRequest
 */
export interface UserInRoleNameRequest {
    /**
     * @generated from protobuf field: Entities.Organization organization = 1;
     */
    organization?: Organization;
    /**
     * @generated from protobuf field: Entities.User user = 2;
     */
    user?: User;
    /**
     * @generated from protobuf field: Entities.OrganizationRole organization_role = 3;
     */
    organizationRole?: OrganizationRole;
}
/**
 * @generated from protobuf message Entities.UserInRoleNameResponse
 */
export interface UserInRoleNameResponse {
    /**
     * @generated from protobuf field: Entities.SimpleAnswer answer = 1;
     */
    answer: SimpleAnswer;
}
/**
 * @generated from protobuf message Entities.UserRequiresAuthorizationResponse
 */
export interface UserRequiresAuthorizationResponse {
    /**
     * @generated from protobuf field: Entities.SimpleAnswer answer = 1;
     */
    answer: SimpleAnswer;
}
/**
 * @generated from protobuf enum Entities.EntityStatus
 */
export enum EntityStatus {
    /**
     * @generated from protobuf enum value: DELETED = 0;
     */
    DELETED = 0,
    /**
     * @generated from protobuf enum value: INACTIVE = 1;
     */
    INACTIVE = 1,
    /**
     * @generated from protobuf enum value: ACTIVE = 10;
     */
    ACTIVE = 10
}
/**
 * @generated from protobuf enum Entities.EntityType
 */
export enum EntityType {
    /**
     * @generated from protobuf enum value: PERSON = 0;
     */
    PERSON = 0,
    /**
     * @generated from protobuf enum value: ORGANIZATION = 1;
     */
    ORGANIZATION = 1,
    /**
     * @generated from protobuf enum value: APPLICATION = 2;
     */
    APPLICATION = 2
}
/**
 * @generated from protobuf enum Entities.SimpleAnswer
 */
export enum SimpleAnswer {
    /**
     * @generated from protobuf enum value: NO = 0;
     */
    NO = 0,
    /**
     * @generated from protobuf enum value: YES = 1;
     */
    YES = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Entity$Type extends MessageType<Entity> {
    constructor() {
        super("Entities.Entity", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "enum", T: () => ["Entities.EntityStatus", EntityStatus] },
            { no: 4, name: "type", kind: "enum", T: () => ["Entities.EntityType", EntityType] },
            { no: 5, name: "created_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Entity>): Entity {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.status = 0;
        message.type = 0;
        message.createdAt = 0n;
        if (value !== undefined)
            reflectionMergePartial<Entity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Entity): Entity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* Entities.EntityStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* Entities.EntityType type */ 4:
                    message.type = reader.int32();
                    break;
                case /* int64 created_at */ 5:
                    message.createdAt = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Entity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* Entities.EntityStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* Entities.EntityType type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        /* int64 created_at = 5; */
        if (message.createdAt !== 0n)
            writer.tag(5, WireType.Varint).int64(message.createdAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.Entity
 */
export const Entity = new Entity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntityProperty$Type extends MessageType<EntityProperty> {
    constructor() {
        super("Entities.EntityProperty", [
            { no: 1, name: "entity_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scope", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EntityProperty>): EntityProperty {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entityId = "";
        message.scope = "";
        message.name = "";
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial<EntityProperty>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntityProperty): EntityProperty {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string entity_id */ 1:
                    message.entityId = reader.string();
                    break;
                case /* string scope */ 2:
                    message.scope = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string value */ 4:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntityProperty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string entity_id = 1; */
        if (message.entityId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.entityId);
        /* string scope = 2; */
        if (message.scope !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scope);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string value = 4; */
        if (message.value !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.EntityProperty
 */
export const EntityProperty = new EntityProperty$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Person$Type extends MessageType<Person> {
    constructor() {
        super("Entities.Person", [
            { no: 1, name: "entity_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "shortname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "birthday", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Person>): Person {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entityId = "";
        message.shortname = "";
        message.birthday = 0n;
        if (value !== undefined)
            reflectionMergePartial<Person>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Person): Person {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string entity_id */ 1:
                    message.entityId = reader.string();
                    break;
                case /* string shortname */ 2:
                    message.shortname = reader.string();
                    break;
                case /* int64 birthday */ 3:
                    message.birthday = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Person, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string entity_id = 1; */
        if (message.entityId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.entityId);
        /* string shortname = 2; */
        if (message.shortname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.shortname);
        /* int64 birthday = 3; */
        if (message.birthday !== 0n)
            writer.tag(3, WireType.Varint).int64(message.birthday);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.Person
 */
export const Person = new Person$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebUser$Type extends MessageType<WebUser> {
    constructor() {
        super("Entities.WebUser", [
            { no: 1, name: "entity_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "password_encoded", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "password_changed_on", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "lang", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "otp_type", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "otp_secret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "otp_added_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 9, name: "webauthn_certificates_added", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<WebUser>): WebUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entityId = "";
        message.password = "";
        message.passwordEncoded = "";
        message.passwordChangedOn = 0n;
        message.lang = "";
        message.otpType = 0n;
        message.otpSecret = "";
        message.otpAddedAt = 0n;
        message.webauthnCertificatesAdded = false;
        if (value !== undefined)
            reflectionMergePartial<WebUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebUser): WebUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string entity_id */ 1:
                    message.entityId = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                case /* string password_encoded */ 3:
                    message.passwordEncoded = reader.string();
                    break;
                case /* int64 password_changed_on */ 4:
                    message.passwordChangedOn = reader.int64().toBigInt();
                    break;
                case /* string lang */ 5:
                    message.lang = reader.string();
                    break;
                case /* int64 otp_type */ 6:
                    message.otpType = reader.int64().toBigInt();
                    break;
                case /* string otp_secret */ 7:
                    message.otpSecret = reader.string();
                    break;
                case /* int64 otp_added_at */ 8:
                    message.otpAddedAt = reader.int64().toBigInt();
                    break;
                case /* bool webauthn_certificates_added */ 9:
                    message.webauthnCertificatesAdded = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string entity_id = 1; */
        if (message.entityId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.entityId);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        /* string password_encoded = 3; */
        if (message.passwordEncoded !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.passwordEncoded);
        /* int64 password_changed_on = 4; */
        if (message.passwordChangedOn !== 0n)
            writer.tag(4, WireType.Varint).int64(message.passwordChangedOn);
        /* string lang = 5; */
        if (message.lang !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lang);
        /* int64 otp_type = 6; */
        if (message.otpType !== 0n)
            writer.tag(6, WireType.Varint).int64(message.otpType);
        /* string otp_secret = 7; */
        if (message.otpSecret !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.otpSecret);
        /* int64 otp_added_at = 8; */
        if (message.otpAddedAt !== 0n)
            writer.tag(8, WireType.Varint).int64(message.otpAddedAt);
        /* bool webauthn_certificates_added = 9; */
        if (message.webauthnCertificatesAdded !== false)
            writer.tag(9, WireType.Varint).bool(message.webauthnCertificatesAdded);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.WebUser
 */
export const WebUser = new WebUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Email$Type extends MessageType<Email> {
    constructor() {
        super("Entities.Email", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "entity_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "valid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "validated_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "primary", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "primary_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "created_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Email>): Email {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.entityId = "";
        message.email = "";
        message.valid = false;
        message.validatedAt = 0n;
        message.primary = false;
        message.primaryAt = 0n;
        message.createdAt = 0n;
        if (value !== undefined)
            reflectionMergePartial<Email>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Email): Email {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* string entity_id */ 2:
                    message.entityId = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* bool valid */ 4:
                    message.valid = reader.bool();
                    break;
                case /* int64 validated_at */ 5:
                    message.validatedAt = reader.int64().toBigInt();
                    break;
                case /* bool primary */ 6:
                    message.primary = reader.bool();
                    break;
                case /* int64 primary_at */ 7:
                    message.primaryAt = reader.int64().toBigInt();
                    break;
                case /* int64 created_at */ 8:
                    message.createdAt = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Email, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string entity_id = 2; */
        if (message.entityId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.entityId);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* bool valid = 4; */
        if (message.valid !== false)
            writer.tag(4, WireType.Varint).bool(message.valid);
        /* int64 validated_at = 5; */
        if (message.validatedAt !== 0n)
            writer.tag(5, WireType.Varint).int64(message.validatedAt);
        /* bool primary = 6; */
        if (message.primary !== false)
            writer.tag(6, WireType.Varint).bool(message.primary);
        /* int64 primary_at = 7; */
        if (message.primaryAt !== 0n)
            writer.tag(7, WireType.Varint).int64(message.primaryAt);
        /* int64 created_at = 8; */
        if (message.createdAt !== 0n)
            writer.tag(8, WireType.Varint).int64(message.createdAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.Email
 */
export const Email = new Email$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("Entities.User", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "entity", kind: "message", T: () => Entity },
            { no: 3, name: "person", kind: "message", T: () => Person },
            { no: 4, name: "webuser", kind: "message", T: () => WebUser },
            { no: 5, name: "emails", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Email },
            { no: 6, name: "primary_email", kind: "message", T: () => Email },
            { no: 7, name: "properties", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EntityProperty }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.emails = [];
        message.properties = [];
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* Entities.Entity entity */ 2:
                    message.entity = Entity.internalBinaryRead(reader, reader.uint32(), options, message.entity);
                    break;
                case /* Entities.Person person */ 3:
                    message.person = Person.internalBinaryRead(reader, reader.uint32(), options, message.person);
                    break;
                case /* Entities.WebUser webuser */ 4:
                    message.webuser = WebUser.internalBinaryRead(reader, reader.uint32(), options, message.webuser);
                    break;
                case /* repeated Entities.Email emails */ 5:
                    message.emails.push(Email.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Entities.Email primary_email */ 6:
                    message.primaryEmail = Email.internalBinaryRead(reader, reader.uint32(), options, message.primaryEmail);
                    break;
                case /* repeated Entities.EntityProperty properties */ 7:
                    message.properties.push(EntityProperty.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* Entities.Entity entity = 2; */
        if (message.entity)
            Entity.internalBinaryWrite(message.entity, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Entities.Person person = 3; */
        if (message.person)
            Person.internalBinaryWrite(message.person, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Entities.WebUser webuser = 4; */
        if (message.webuser)
            WebUser.internalBinaryWrite(message.webuser, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated Entities.Email emails = 5; */
        for (let i = 0; i < message.emails.length; i++)
            Email.internalBinaryWrite(message.emails[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Entities.Email primary_email = 6; */
        if (message.primaryEmail)
            Email.internalBinaryWrite(message.primaryEmail, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated Entities.EntityProperty properties = 7; */
        for (let i = 0; i < message.properties.length; i++)
            EntityProperty.internalBinaryWrite(message.properties[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Organization$Type extends MessageType<Organization> {
    constructor() {
        super("Entities.Organization", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "entity", kind: "message", T: () => Entity },
            { no: 3, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "headquarters", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "roles", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrganizationRole },
            { no: 7, name: "properties", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EntityProperty }
        ]);
    }
    create(value?: PartialMessage<Organization>): Organization {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.slug = "";
        message.headquarters = "";
        message.roles = [];
        message.properties = [];
        if (value !== undefined)
            reflectionMergePartial<Organization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Organization): Organization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* Entities.Entity entity */ 2:
                    message.entity = Entity.internalBinaryRead(reader, reader.uint32(), options, message.entity);
                    break;
                case /* string slug */ 3:
                    message.slug = reader.string();
                    break;
                case /* string headquarters */ 4:
                    message.headquarters = reader.string();
                    break;
                case /* repeated Entities.OrganizationRole roles */ 6:
                    message.roles.push(OrganizationRole.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Entities.EntityProperty properties */ 7:
                    message.properties.push(EntityProperty.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Organization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* Entities.Entity entity = 2; */
        if (message.entity)
            Entity.internalBinaryWrite(message.entity, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string slug = 3; */
        if (message.slug !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.slug);
        /* string headquarters = 4; */
        if (message.headquarters !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.headquarters);
        /* repeated Entities.OrganizationRole roles = 6; */
        for (let i = 0; i < message.roles.length; i++)
            OrganizationRole.internalBinaryWrite(message.roles[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated Entities.EntityProperty properties = 7; */
        for (let i = 0; i < message.properties.length; i++)
            EntityProperty.internalBinaryWrite(message.properties[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.Organization
 */
export const Organization = new Organization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationRole$Type extends MessageType<OrganizationRole> {
    constructor() {
        super("Entities.OrganizationRole", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<OrganizationRole>): OrganizationRole {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.organizationId = "";
        message.name = "";
        message.comment = "";
        message.active = false;
        if (value !== undefined)
            reflectionMergePartial<OrganizationRole>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrganizationRole): OrganizationRole {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string organization_id */ 2:
                    message.organizationId = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string comment */ 4:
                    message.comment = reader.string();
                    break;
                case /* bool active */ 5:
                    message.active = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrganizationRole, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string organization_id = 2; */
        if (message.organizationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.organizationId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string comment = 4; */
        if (message.comment !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.comment);
        /* bool active = 5; */
        if (message.active !== false)
            writer.tag(5, WireType.Varint).bool(message.active);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.OrganizationRole
 */
export const OrganizationRole = new OrganizationRole$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RolePermissionMap$Type extends MessageType<RolePermissionMap> {
    constructor() {
        super("Entities.RolePermissionMap", [
            { no: 1, name: "owner", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "master", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "operator", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "undelete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "delete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "create", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "edit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "view", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RolePermissionMap>): RolePermissionMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.owner = false;
        message.master = false;
        message.operator = false;
        message.undelete = false;
        message.delete = false;
        message.create = false;
        message.edit = false;
        message.view = false;
        if (value !== undefined)
            reflectionMergePartial<RolePermissionMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RolePermissionMap): RolePermissionMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool owner */ 1:
                    message.owner = reader.bool();
                    break;
                case /* bool master */ 2:
                    message.master = reader.bool();
                    break;
                case /* bool operator */ 3:
                    message.operator = reader.bool();
                    break;
                case /* bool undelete */ 4:
                    message.undelete = reader.bool();
                    break;
                case /* bool delete */ 5:
                    message.delete = reader.bool();
                    break;
                case /* bool create */ 6:
                    message.create = reader.bool();
                    break;
                case /* bool edit */ 7:
                    message.edit = reader.bool();
                    break;
                case /* bool view */ 8:
                    message.view = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RolePermissionMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool owner = 1; */
        if (message.owner !== false)
            writer.tag(1, WireType.Varint).bool(message.owner);
        /* bool master = 2; */
        if (message.master !== false)
            writer.tag(2, WireType.Varint).bool(message.master);
        /* bool operator = 3; */
        if (message.operator !== false)
            writer.tag(3, WireType.Varint).bool(message.operator);
        /* bool undelete = 4; */
        if (message.undelete !== false)
            writer.tag(4, WireType.Varint).bool(message.undelete);
        /* bool delete = 5; */
        if (message.delete !== false)
            writer.tag(5, WireType.Varint).bool(message.delete);
        /* bool create = 6; */
        if (message.create !== false)
            writer.tag(6, WireType.Varint).bool(message.create);
        /* bool edit = 7; */
        if (message.edit !== false)
            writer.tag(7, WireType.Varint).bool(message.edit);
        /* bool view = 8; */
        if (message.view !== false)
            writer.tag(8, WireType.Varint).bool(message.view);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.RolePermissionMap
 */
export const RolePermissionMap = new RolePermissionMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationRoleResource$Type extends MessageType<OrganizationRoleResource> {
    constructor() {
        super("Entities.OrganizationRoleResource", [
            { no: 1, name: "role_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "resource", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "permission_map", kind: "message", T: () => RolePermissionMap }
        ]);
    }
    create(value?: PartialMessage<OrganizationRoleResource>): OrganizationRoleResource {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.roleId = "";
        message.resource = "";
        if (value !== undefined)
            reflectionMergePartial<OrganizationRoleResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrganizationRoleResource): OrganizationRoleResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string role_id */ 1:
                    message.roleId = reader.string();
                    break;
                case /* string resource */ 2:
                    message.resource = reader.string();
                    break;
                case /* Entities.RolePermissionMap permission_map */ 3:
                    message.permissionMap = RolePermissionMap.internalBinaryRead(reader, reader.uint32(), options, message.permissionMap);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrganizationRoleResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string role_id = 1; */
        if (message.roleId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.roleId);
        /* string resource = 2; */
        if (message.resource !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.resource);
        /* Entities.RolePermissionMap permission_map = 3; */
        if (message.permissionMap)
            RolePermissionMap.internalBinaryWrite(message.permissionMap, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.OrganizationRoleResource
 */
export const OrganizationRoleResource = new OrganizationRoleResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplicationDomain$Type extends MessageType<ApplicationDomain> {
    constructor() {
        super("Entities.ApplicationDomain", [
            { no: 1, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "validated_on", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ApplicationDomain>): ApplicationDomain {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.domain = "";
        message.validatedOn = 0n;
        if (value !== undefined)
            reflectionMergePartial<ApplicationDomain>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplicationDomain): ApplicationDomain {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string domain */ 1:
                    message.domain = reader.string();
                    break;
                case /* int64 validated_on */ 2:
                    message.validatedOn = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplicationDomain, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string domain = 1; */
        if (message.domain !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.domain);
        /* int64 validated_on = 2; */
        if (message.validatedOn !== 0n)
            writer.tag(2, WireType.Varint).int64(message.validatedOn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.ApplicationDomain
 */
export const ApplicationDomain = new ApplicationDomain$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Application$Type extends MessageType<Application> {
    constructor() {
        super("Entities.Application", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "entity", kind: "message", T: () => Entity },
            { no: 3, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "return_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "domains", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApplicationDomain },
            { no: 7, name: "properties", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EntityProperty }
        ]);
    }
    create(value?: PartialMessage<Application>): Application {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.key = "";
        message.returnUrl = "";
        message.domains = [];
        message.properties = [];
        if (value !== undefined)
            reflectionMergePartial<Application>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Application): Application {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* Entities.Entity entity */ 2:
                    message.entity = Entity.internalBinaryRead(reader, reader.uint32(), options, message.entity);
                    break;
                case /* string key */ 3:
                    message.key = reader.string();
                    break;
                case /* string return_url */ 4:
                    message.returnUrl = reader.string();
                    break;
                case /* repeated Entities.ApplicationDomain domains */ 5:
                    message.domains.push(ApplicationDomain.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Entities.EntityProperty properties */ 7:
                    message.properties.push(EntityProperty.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Application, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* Entities.Entity entity = 2; */
        if (message.entity)
            Entity.internalBinaryWrite(message.entity, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string key = 3; */
        if (message.key !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.key);
        /* string return_url = 4; */
        if (message.returnUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.returnUrl);
        /* repeated Entities.ApplicationDomain domains = 5; */
        for (let i = 0; i < message.domains.length; i++)
            ApplicationDomain.internalBinaryWrite(message.domains[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated Entities.EntityProperty properties = 7; */
        for (let i = 0; i < message.properties.length; i++)
            EntityProperty.internalBinaryWrite(message.properties[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.Application
 */
export const Application = new Application$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RolePermissionMapByUserResourcesOrganizationRequest$Type extends MessageType<RolePermissionMapByUserResourcesOrganizationRequest> {
    constructor() {
        super("Entities.RolePermissionMapByUserResourcesOrganizationRequest", [
            { no: 1, name: "organization", kind: "message", T: () => Organization },
            { no: 2, name: "user", kind: "message", T: () => User },
            { no: 3, name: "role_resources", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrganizationRoleResource }
        ]);
    }
    create(value?: PartialMessage<RolePermissionMapByUserResourcesOrganizationRequest>): RolePermissionMapByUserResourcesOrganizationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.roleResources = [];
        if (value !== undefined)
            reflectionMergePartial<RolePermissionMapByUserResourcesOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RolePermissionMapByUserResourcesOrganizationRequest): RolePermissionMapByUserResourcesOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Entities.Organization organization */ 1:
                    message.organization = Organization.internalBinaryRead(reader, reader.uint32(), options, message.organization);
                    break;
                case /* Entities.User user */ 2:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* repeated Entities.OrganizationRoleResource role_resources */ 3:
                    message.roleResources.push(OrganizationRoleResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RolePermissionMapByUserResourcesOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Entities.Organization organization = 1; */
        if (message.organization)
            Organization.internalBinaryWrite(message.organization, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Entities.User user = 2; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated Entities.OrganizationRoleResource role_resources = 3; */
        for (let i = 0; i < message.roleResources.length; i++)
            OrganizationRoleResource.internalBinaryWrite(message.roleResources[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.RolePermissionMapByUserResourcesOrganizationRequest
 */
export const RolePermissionMapByUserResourcesOrganizationRequest = new RolePermissionMapByUserResourcesOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RolePermissionMapByUserResourcesOrganizationResponse$Type extends MessageType<RolePermissionMapByUserResourcesOrganizationResponse> {
    constructor() {
        super("Entities.RolePermissionMapByUserResourcesOrganizationResponse", [
            { no: 1, name: "role_resources", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrganizationRoleResource }
        ]);
    }
    create(value?: PartialMessage<RolePermissionMapByUserResourcesOrganizationResponse>): RolePermissionMapByUserResourcesOrganizationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.roleResources = [];
        if (value !== undefined)
            reflectionMergePartial<RolePermissionMapByUserResourcesOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RolePermissionMapByUserResourcesOrganizationResponse): RolePermissionMapByUserResourcesOrganizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Entities.OrganizationRoleResource role_resources */ 1:
                    message.roleResources.push(OrganizationRoleResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RolePermissionMapByUserResourcesOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Entities.OrganizationRoleResource role_resources = 1; */
        for (let i = 0; i < message.roleResources.length; i++)
            OrganizationRoleResource.internalBinaryWrite(message.roleResources[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.RolePermissionMapByUserResourcesOrganizationResponse
 */
export const RolePermissionMapByUserResourcesOrganizationResponse = new RolePermissionMapByUserResourcesOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInRoleNameRequest$Type extends MessageType<UserInRoleNameRequest> {
    constructor() {
        super("Entities.UserInRoleNameRequest", [
            { no: 1, name: "organization", kind: "message", T: () => Organization },
            { no: 2, name: "user", kind: "message", T: () => User },
            { no: 3, name: "organization_role", kind: "message", T: () => OrganizationRole }
        ]);
    }
    create(value?: PartialMessage<UserInRoleNameRequest>): UserInRoleNameRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UserInRoleNameRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserInRoleNameRequest): UserInRoleNameRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Entities.Organization organization */ 1:
                    message.organization = Organization.internalBinaryRead(reader, reader.uint32(), options, message.organization);
                    break;
                case /* Entities.User user */ 2:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* Entities.OrganizationRole organization_role */ 3:
                    message.organizationRole = OrganizationRole.internalBinaryRead(reader, reader.uint32(), options, message.organizationRole);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserInRoleNameRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Entities.Organization organization = 1; */
        if (message.organization)
            Organization.internalBinaryWrite(message.organization, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Entities.User user = 2; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Entities.OrganizationRole organization_role = 3; */
        if (message.organizationRole)
            OrganizationRole.internalBinaryWrite(message.organizationRole, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.UserInRoleNameRequest
 */
export const UserInRoleNameRequest = new UserInRoleNameRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInRoleNameResponse$Type extends MessageType<UserInRoleNameResponse> {
    constructor() {
        super("Entities.UserInRoleNameResponse", [
            { no: 1, name: "answer", kind: "enum", T: () => ["Entities.SimpleAnswer", SimpleAnswer] }
        ]);
    }
    create(value?: PartialMessage<UserInRoleNameResponse>): UserInRoleNameResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.answer = 0;
        if (value !== undefined)
            reflectionMergePartial<UserInRoleNameResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserInRoleNameResponse): UserInRoleNameResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Entities.SimpleAnswer answer */ 1:
                    message.answer = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserInRoleNameResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Entities.SimpleAnswer answer = 1; */
        if (message.answer !== 0)
            writer.tag(1, WireType.Varint).int32(message.answer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.UserInRoleNameResponse
 */
export const UserInRoleNameResponse = new UserInRoleNameResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRequiresAuthorizationResponse$Type extends MessageType<UserRequiresAuthorizationResponse> {
    constructor() {
        super("Entities.UserRequiresAuthorizationResponse", [
            { no: 1, name: "answer", kind: "enum", T: () => ["Entities.SimpleAnswer", SimpleAnswer] }
        ]);
    }
    create(value?: PartialMessage<UserRequiresAuthorizationResponse>): UserRequiresAuthorizationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.answer = 0;
        if (value !== undefined)
            reflectionMergePartial<UserRequiresAuthorizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRequiresAuthorizationResponse): UserRequiresAuthorizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Entities.SimpleAnswer answer */ 1:
                    message.answer = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRequiresAuthorizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Entities.SimpleAnswer answer = 1; */
        if (message.answer !== 0)
            writer.tag(1, WireType.Varint).int32(message.answer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Entities.UserRequiresAuthorizationResponse
 */
export const UserRequiresAuthorizationResponse = new UserRequiresAuthorizationResponse$Type();
/**
 * @generated ServiceType for protobuf service Entities.Entities
 */
export const Entities = new ServiceType("Entities.Entities", [
    { name: "getUserByEntityId", options: {}, I: Entity, O: User },
    { name: "userRequiresAuthorization", options: {}, I: Entity, O: UserRequiresAuthorizationResponse },
    { name: "getApplicationByEntityId", options: {}, I: Entity, O: Application },
    { name: "getRolePermissionMapByUserResourcesOrganization", options: {}, I: RolePermissionMapByUserResourcesOrganizationRequest, O: RolePermissionMapByUserResourcesOrganizationResponse },
    { name: "isUserInRoleName", options: {}, I: UserInRoleNameRequest, O: UserInRoleNameResponse }
]);
