import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import ExternalApplicationContext from "../../context/ExternalApplicationContext";
import currentUser, { UserInfo } from "../../context/CurrentUser";
import {
  AuthenticationResponseJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON,
} from "@simplewebauthn/types";

export { UserInfo } from "../../context/CurrentUser";

let prefix = "/s/entities";
if (
  window.config &&
  window.config.endpoints &&
  window.config.endpoints.entities
) {
  prefix = window.config.endpoints.entities;
}

export function getUserInfo(): Observable<UserInfo> {
  return currentUser.authenticatedGet<UserInfo>(`${prefix}/users/me`).pipe(
    map((res) => {
      return res.response;
    }),
  );
}

export interface UserEmail {
  id: bigint;
  entity_id: string;
  email: string;
  valid: boolean;
  validated_at: string;
  primary: boolean;
  primary_at: string;
  created_at: string;
}

export function getUserEmails(user_id: string): Observable<UserEmail[]> {
  return currentUser
    .authenticatedGet<UserEmail[]>(`${prefix}/users/${user_id}/emails`)
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export interface UpdateInfoRequest {
  shortname?: string;
  name?: string;
  lang?: string;
  birthday?: Date;
}

export interface UpdateInfoResponse {
  success: boolean;
}

export function userUpdateInfo(
  user_id: string,
  args: UpdateInfoRequest,
): Observable<UpdateInfoResponse> {
  return currentUser
    .authenticatedPut<UpdateInfoResponse>(`${prefix}/users/${user_id}`, args)
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export interface UserSetPasswordResponse {
  success: boolean;
}

export function userSetPassword(
  user_id: string,
  new_password: string,
): Observable<UserSetPasswordResponse> {
  return currentUser
    .authenticatedPost<UserSetPasswordResponse>(
      `${prefix}/users/${user_id}/set_password`,
      {
        new_password,
      },
    )
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export function userChangePassword(
  user_id: string,
  current_password: string,
  new_password: string,
): Observable<UserSetPasswordResponse> {
  return currentUser
    .authenticatedPost<UserSetPasswordResponse>(
      `${prefix}/users/${user_id}/change_password`,
      {
        password: current_password,
        new_password,
      },
    )
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export interface UserAddEmailResponse {
  success: boolean;
  email: UserEmail;
}

export function emailAdd(
  user_id: string,
  email: string,
  context?: ExternalApplicationContext,
): Observable<UserAddEmailResponse> {
  return currentUser
    .authenticatedPost<UserAddEmailResponse>(
      `${prefix}/users/${user_id}/emails`,
      {
        email,
        context,
      },
    )
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export interface UserEmailActionResponse {
  success: boolean;
}

export function emailMakePrimary(
  user_id: string,
  email_id: string,
): Observable<UserEmailActionResponse> {
  return currentUser
    .authenticatedPost<UserEmailActionResponse>(
      `${prefix}/users/${user_id}/emails/${email_id}/make_primary`,
      {},
    )
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export function emailRemove(
  user_id: string,
  email_id: string,
): Observable<UserEmailActionResponse> {
  return currentUser
    .authenticatedDelete<UserEmailActionResponse>(
      `${prefix}/users/${user_id}/emails/${email_id}`,
      {},
    )
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export interface UserProperty {
  entity_id: string;
  scope: string;
  name: string;
  value: string;
}

export function userGetProperties(
  user_id: string,
  scope: string,
): Observable<UserProperty[]> {
  return currentUser
    .authenticatedGet<
      UserProperty[]
    >(`${prefix}/users/${user_id}/properties/${scope}`)
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export function userGetProperty(
  user_id: string,
  scope: string,
  property: string,
): Observable<UserProperty> {
  return currentUser
    .authenticatedGet<UserProperty>(
      `${prefix}/users/${user_id}/properties/${scope}/${property}`,
    )
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export interface UserPropertiesActionResponse {
  success: boolean;
}

export function userSetProperty(
  user_id: string,
  scope: string,
  property: string,
  value: string,
): Observable<UserPropertiesActionResponse> {
  return currentUser
    .authenticatedPost<UserPropertiesActionResponse>(
      `${prefix}/users/${user_id}/properties/${scope}/${property}`,
      {
        value,
      },
    )
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export function userDeleteProperty(
  user_id: string,
  scope: string,
  property: string,
): Observable<UserPropertiesActionResponse> {
  return currentUser
    .authenticatedDelete<UserPropertiesActionResponse>(
      `${prefix}/users/${user_id}/properties/${scope}/${property}`,
      {},
    )
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export function userWebAuthnRegister(
  user_id: string,
): Observable<{ sessionID: string; response: never }> {
  return currentUser
    .authenticatedPost<never>(`${prefix}/users/${user_id}/webauth`, {})
    .pipe(
      map((res) => {
        return {
          sessionID: res.responseHeaders["x-session-id"],
          response: res.response,
        };
      }),
    );
}

export function userWebAuthnRegisterFinish(
  user_id: string,
  session_id: string,
  registration: RegistrationResponseJSON,
): Observable<never> {
  return currentUser
    .authenticatedRequest<never>({
      method: "PUT",
      url: `${prefix}/users/${user_id}/webauth`,
      body: registration,
      headers: {
        "X-Session-ID": session_id,
      },
    })
    .pipe(map((res) => res.response));
}

export type WebAuthnCertificate = {
  ID: number;
  EntityID: string;
  Name: string;
  Type: number;
  CredentialID: string;
  CredentialJSON: string;
  CreatedAt: string;
  UpdatedAt: string;
  LastUsedAt?: string;
  CreatedBy: string;
  UpdatedBy: string;
};

export function userWebAuthnGetCertificates(
  user_id: string,
): Observable<Array<WebAuthnCertificate>> {
  return currentUser
    .authenticatedGet<
      Array<WebAuthnCertificate>
    >(`${prefix}/users/${user_id}/webauth`)
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export type WebAuthnUnauthLoginResponse = {
  publicKey: PublicKeyCredentialRequestOptionsJSON;
};

export type WebAuthnLoginResponse = {
  publicKey: PublicKeyCredentialRequestOptionsJSON;
};

export function userWebAuthnLogin(
  user_id: string,
): Observable<{ sessionID: string; response: WebAuthnLoginResponse }> {
  return currentUser
    .authenticatedPost<never>(`${prefix}/users/${user_id}/webauth/login`, {})
    .pipe(
      map((res) => {
        return {
          sessionID: res.responseHeaders["x-session-id"],
          response: res.response,
        };
      }),
    );
}

export function userWebAuthnLoginFinish(
  user_id: string,
  session_id: string,
  registration: AuthenticationResponseJSON,
): Observable<{ success: boolean; authorization_token: string }> {
  return currentUser
    .authenticatedRequest<{ success: boolean; authorization_token: string }>({
      method: "POST",
      url: `${prefix}/users/${user_id}/webauth/login_end`,
      body: registration,
      headers: {
        "X-Session-ID": session_id,
      },
    })
    .pipe(map((res) => res.response));
}

export function userWebAuthnEditCertificateName(
  user_id: string,
  certificate_id: number,
  new_name: string,
): Observable<WebAuthnCertificate> {
  return currentUser
    .authenticatedRequest<never>({
      method: "PUT",
      url: `${prefix}/users/${user_id}/webauth/${certificate_id}`,
      body: {
        Name: new_name,
      },
    })
    .pipe(map((res) => res.response));
}

export function userWebAuthnDeleteCertificate(
  user_id: string,
  certificate_id: number,
): Observable<""> {
  return currentUser
    .authenticatedRequest<never>({
      method: "DELETE",
      url: `${prefix}/users/${user_id}/webauth/${certificate_id}`,
      body: {},
    })
    .pipe(map((res) => res.response));
}

export function validateAuthorizationToken(
  user_id: string,
  token: string,
): Observable<{ valid: boolean; expires: string }> {
  return currentUser
    .authenticatedRequest<never>({
      method: "POST",
      url: `${prefix}/users/${user_id}/validate_authorization_token`,
      body: {
        token,
      },
    })
    .pipe(map((res) => res.response));
}
export function authorizeSession(
  user_id: string,
  token: string,
): Observable<{ valid: boolean; expires: string }> {
  return currentUser
    .authenticatedRequest<never>({
      method: "POST",
      url: `${prefix}/users/${user_id}/authorize_session`,
      body: {
        token,
      },
    })
    .pipe(map((res) => res.response));
}

export function userInitTotp(
  user_id: string,
): Observable<{ sessionID: string; url: string }> {
  return currentUser
    .authenticatedRequest<{ url: string }>({
      method: "POST",
      url: `${prefix}/users/${user_id}/totp`,
      body: {},
    })
    .pipe(
      map((res) => {
        return {
          sessionID: res.responseHeaders["x-session-id"],
          url: res.response.url,
        };
      }),
    );
}

export function userSetupTotp(
  user_id: string,
  session_id: string,
  code: string,
): Observable<boolean> {
  return currentUser
    .authenticatedRequest<{ url: string }>({
      method: "PUT",
      url: `${prefix}/users/${user_id}/totp`,
      body: {
        code,
      },
      headers: {
        "X-Session-ID": session_id,
      },
    })
    .pipe(
      map(() => {
        return true;
      }),
    );
}

export function userValidateTotp(
  user_id: string,
  code: string,
): Observable<{ success: boolean; authorization_token: string }> {
  return currentUser
    .authenticatedRequest<{ success: boolean; authorization_token: string }>({
      method: "POST",
      url: `${prefix}/users/${user_id}/totp/validate`,
      body: {
        code,
      },
    })
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}

export function userGetTotp(
  user_id: string,
  authorization_token: string,
): Observable<{ url: string }> {
  return currentUser
    .authenticatedRequest<{ url: string }>({
      method: "GET",
      url: `${prefix}/users/${user_id}/totp`,
      headers: {
        "X-Authorization-Token": authorization_token,
      },
    })
    .pipe(
      map((res) => {
        return res.response;
      }),
    );
}
