import { map } from "rxjs/operators";
import {
  UserInfo,
  getUserEmails,
  userUpdateInfo,
  UpdateInfoRequest,
  emailMakePrimary,
  emailRemove,
  emailAdd,
  userSetPassword,
  userChangePassword,
  UserEmail,
  userWebAuthnGetCertificates,
  WebAuthnCertificate,
} from "../modules/accounts/endpoints";
import { ChangeNotifier } from "../modules/customstate/ChangeNotifier";
import { User, Email as EntitiesEmail } from "./entitiesproto/entities";

export class Email {
  public id: bigint = null;

  public email = "";

  public entity_id = "";

  public valid = false;

  public validatedAt: Date = null;

  public primary = false;

  public primaryAt: Date = null;

  public createdAt: Date = null;

  makePrimary() {
    return emailMakePrimary(this.entity_id, this.id.toString());
  }

  removeEmail() {
    return emailRemove(this.entity_id, this.id.toString());
  }
}

export class Role {
  public roleId = "";

  public entityId = "";

  public from: Date = null;

  public to: Date = null;

  public active = 0;
}

export class OAuth2Instances {
  public provider = "";

  public identificator = "";

  public createdAt: Date = null;
}

export enum OTPType {
  NONE = 0,
  TOTP = 1,
  HOTP = 2,
}

function GetOtpTypeFromNumber(id: number): OTPType {
  if (OTPType[id]) {
    if (id === 1) {
      return OTPType.TOTP;
    } else if (id === 2) {
      return OTPType.HOTP;
    } else {
      return OTPType.NONE;
    }
  }
  return OTPType.NONE;
}

export class Person extends ChangeNotifier {
  public id = "";

  public name = "";

  public status = 0;

  public created_at = null;

  public shortname = "";

  public birthday = null;

  public password_changed_on: Date = null;

  public lang = "";

  public email = "";

  private emailsLoadedAt: Date = null;
  loadingEmails = false;
  public emails: Email[] = [];

  private rolesLoadedAt: Date = null;
  public roles: Role[] = [];

  private totpAddedDate: Date = null;
  private totpType: OTPType = OTPType.NONE;

  get hasTotp() {
    return this.totpAddedDate !== null;
  }

  public manualOTPSet() {
    this.totpType = OTPType.TOTP;
    this.totpAddedDate = new Date();
  }

  public webauthnCertificatesLoadedAt: Date | null = null;
  loadingWebauthnCertificates = false;
  public webauthnCertificates: Array<WebAuthnCertificate> = [];
  public hasWebAuthnCertificates = false;

  public fillFromCurrentUserEndpoint(userEndpointData: UserInfo) {
    this.id = userEndpointData.ID;
    this.name = userEndpointData.Name;
    this.status = userEndpointData.Status;

    this.shortname = userEndpointData.Shortname;

    if (userEndpointData.Birthday !== null) {
      this.birthday = new Date(userEndpointData.Birthday);
    }

    if (userEndpointData.PasswordChangedOn !== null) {
      this.password_changed_on = new Date(userEndpointData.PasswordChangedOn);
    }

    this.lang = userEndpointData.Lang;

    this.email = userEndpointData.Email;
    if (userEndpointData.Emails) {
      this.loadingEmails = false;
      this.fillEmailsFromEndpointData(userEndpointData.Emails);
    }

    this.totpType = userEndpointData.OTPType as OTPType;
    if (userEndpointData.OTPAddedAt !== null) {
      this.totpAddedDate = new Date(userEndpointData.OTPAddedAt);
    }
    this.hasWebAuthnCertificates = userEndpointData.WebauthnCertificatesAdded;
  }

  public fillFromCurrentUserProto(userEntity: User) {
    this.id = userEntity.id;
    this.name = userEntity.entity.name;
    this.status = userEntity.entity.status;

    this.shortname = userEntity.person.shortname;

    if (userEntity.person.birthday !== null) {
      this.birthday = new Date(Number(userEntity.person.birthday) * 1000);
    }

    if (userEntity.webuser.passwordChangedOn !== null) {
      this.password_changed_on = new Date(
        Number(userEntity.webuser.passwordChangedOn) * 1000,
      );
    }
    this.totpType = GetOtpTypeFromNumber(Number(userEntity.webuser.otpType));
    if (userEntity.webuser.otpAddedAt !== null) {
      this.totpAddedDate = new Date(Number(userEntity.webuser.otpAddedAt) * 1000);
    }

    this.hasWebAuthnCertificates =
      userEntity.webuser.webauthnCertificatesAdded;

    this.lang = userEntity.webuser.lang;

    this.email = userEntity.primaryEmail.email;
    if (userEntity.emails) {
      this.loadingEmails = false;
      this.fillEmailsFromProtoData(userEntity.emails);
    }
  }

  public getShortname() {
    if (this.shortname !== "") {
      return this.shortname;
    }
    if (this.name !== "") {
      return this.name.split(" ")[0];
    }
    return this.email.split("@")[0];
  }

  public setName(newName) {
    console.log("Setting new name", newName);
    this.name = newName;
    this.notifyListeners();
    return this.updateInformation({
      name: this.name,
    }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  public setShortname(newName) {
    console.log("Setting new shortname", newName);
    this.shortname = newName;
    this.notifyListeners();
    return this.updateInformation({
      shortname: this.shortname,
    }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  public setLanguage(newLang) {
    console.log("Setting new Lang", newLang);
    this.lang = newLang;
    this.notifyListeners();
    return this.updateInformation({
      lang: this.lang,
    }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  public setBirthday(newBirthday: Date | null) {
    this.birthday = newBirthday;
    this.notifyListeners();
    return this.updateInformation({
      birthday: this.birthday,
    }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  public updateInformation(info: UpdateInfoRequest) {
    return userUpdateInfo(this.id, info).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  public setPassword(newPassword: string) {
    return userSetPassword(this.id, newPassword).pipe(
      map((response) => {
        this.password_changed_on = new Date();
        return response;
      }),
    );
  }

  public updatePassword(currentPassword: string, newPassword: string) {
    return userChangePassword(this.id, currentPassword, newPassword).pipe(
      map((response) => {
        this.password_changed_on = new Date();
        return response;
      }),
    );
  }
  fillEmailsFromProtoData(emails: EntitiesEmail[]) {
    this.emails = [];
    this.emailsLoadedAt = new Date();
    emails.forEach((emailData) => {
      const email = new Email();
      email.id = emailData.id;
      email.entity_id = emailData.entityId;
      email.email = emailData.email;
      email.valid = emailData.valid;
      email.primary = emailData.primary;
      if (email.valid) {
        email.validatedAt = new Date(Number(emailData.validatedAt));
      }
      if (email.primary) {
        email.primaryAt = new Date(Number(emailData.primaryAt));
      }
      email.createdAt = new Date(Number(emailData.createdAt));
      this.emails.push(email);
      if (email.primary) {
        this.email = email.email;
      }
    });
    return this.emails;
  }
  fillEmailsFromEndpointData(emails: UserEmail[]) {
    this.emails = [];
    this.emailsLoadedAt = new Date();
    emails.forEach((emailData) => {
      const email = new Email();
      email.id = emailData.id;
      email.entity_id = emailData.entity_id;
      email.email = emailData.email;
      email.valid = emailData.valid;
      email.primary = emailData.primary;
      if (email.valid) {
        email.validatedAt = new Date(emailData.validated_at);
      }
      if (email.primary) {
        email.primaryAt = new Date(emailData.primary_at);
      }
      email.createdAt = new Date(emailData.created_at);
      this.emails.push(email);
      if (email.primary) {
        this.email = email.email;
      }
    });
    return this.emails;
  }

  public getEmails() {
    this.loadingEmails = true;
    this.notifyListeners();
    return getUserEmails(this.id).pipe(
      map((emails) => {
        this.fillEmailsFromEndpointData(emails);
        this.loadingEmails = false;
        this.notifyListeners();
        return this.emails;
      }),
    );
  }

  public getWebauthnCertificates() {
    this.loadingWebauthnCertificates = true;
    this.notifyListeners();
    return userWebAuthnGetCertificates(this.id).pipe(
      map((webauthnCertificates) => {
        // this.fillEmailsFromEndpointData(emails);
        console.log("WebauthnCerti", webauthnCertificates);
        this.webauthnCertificates = webauthnCertificates;
        this.hasWebAuthnCertificates = this.webauthnCertificates.length > 0;
        this.loadingWebauthnCertificates = false;
        this.webauthnCertificatesLoadedAt = new Date();
        this.notifyListeners();
        return this.webauthnCertificates;
      }),
    );
  }

  public addEmail(emailAddress: string) {
    return emailAdd(this.id, emailAddress, null);
  }

  public getRoles() {
    console.error("Not Implemented", this.id);
  }

  public getOauthInstances() {
    console.error("Not Implemented", this.id);
  }
}
