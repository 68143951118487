import { useEffect } from "react";
import useComponentRefresh from "./useComponentRefresh";

export function observe<T extends { subscribe: (fn: () => void) => { unsubscribe: () => void } }>(subscribable: T): T {
  const forceUpdate = useComponentRefresh();

  useEffect(() => {
    if (subscribable && subscribable.subscribe) {
      const subscription = subscribable.subscribe(() => {
        // console.log("subscribable changed", subscribable.constructor.name);
        forceUpdate();
      });
      return () => {
        return subscription.unsubscribe();
      };
    }
  }, [subscribable]);

  return subscribable;
}
