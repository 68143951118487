/* eslint-disable @typescript-eslint/no-empty-function */
import { throttle } from "lodash";
import { Observable, of } from "rxjs";
import { AjaxConfig, AjaxResponse } from "rxjs/ajax";
import { map, mergeMap, catchError, repeat } from "rxjs/operators";
import EventEmitter from "eventemitter3";
import validate_jwt_token from "../modules/utils/validate_jwt_token";
import { Person } from "../models/person";
import { entitiesEndpointPrefix } from "./config";
import ExternalApplicationContext from "./ExternalApplicationContext";
import { request, requestPost } from "./AjaxJSONRequest";
import { fetchJSONRequest, fetchProtoRequest, fetchRequest } from "./fetchRequest";
import { ChangeNotifier } from "../modules/customstate/ChangeNotifier";
import {
  UserEmail,
  WebAuthnUnauthLoginResponse,
} from "../modules/accounts/endpoints";
import { User } from "../models/entitiesproto/entities";
import { AuthenticationResponseJSON } from "@simplewebauthn/types";

const STORAGE_NAME = "session_token";

const { localStorage } = window;

declare global {
  interface Window {
    proto_user?: string;
    CurrentUser: CurrentUser;
  }
}

interface IZJWTToken {
  uid: string;
  exp: number;
}

export const EventLogin = Symbol("_login");
export const EventLogout = Symbol("_logout");
export const EventChangeUser = Symbol("_changeUser");
export const EventTouch = Symbol("_touch");
export const EventTouched = Symbol("_touched");
export const EventReload = Symbol("_reload");
export const EventResetIdle = Symbol("_resetidle");

export const NoTokenError = new Error("No Token Error");

export class CurrentUser extends ChangeNotifier {
  currentPerson: Person = null;

  calledUserInfoEndpoint = false;

  applicationToken: string = null;

  tokenSetAt: Date = null;

  tokenExpiration: number = null;

  emitter = new EventEmitter();

  constructor() {
    super();
    this.loadCurrentUser();
    this.loadAppToken();

    this.emitter.on(EventLogin, () => {
      this.fetchToken()
        .then(() => { })
        .catch(() => { });
    });
    this.emitter.on(EventLogout, () => {
      this.removeAppToken();
    });
    this.emitter.on(EventChangeUser, () => {
      this.fetchToken()
        .then(() => { })
        .catch(() => { });
    });
    this.emitter.on(EventTouch, () => {
      this.touchSession();
    });
    this.emitter.on(EventReload, () => {
      this.getUserInfo()
        .then(() => { })
        .catch(() => { });
    });
    const throttledSessionTouch = throttle(this.touchSession.bind(this), 30000);
    this.emitter.on(EventResetIdle, throttledSessionTouch);
    document.addEventListener("mousemove", throttledSessionTouch);
    document.addEventListener("keypress", throttledSessionTouch);
    throttledSessionTouch();
  }

  get ID() {
    return this.currentPerson ? this.currentPerson.id : null;
  }

  // AppToken
  loadAppToken() {
    if (localStorage.getItem(STORAGE_NAME)) {
      if (this.currentPerson === null) {
        this.removeAppToken();
        return;
      }
      const storageData = JSON.parse(localStorage.getItem(STORAGE_NAME));
      try {
        const token = validate_jwt_token<IZJWTToken>(storageData.token);
        const decoded = token.body;
        if (decoded.uid && this.currentPerson.id === decoded.uid) {
          this.applicationToken = storageData.token;
          this.tokenExpiration = decoded.exp;
          this.tokenSetAt = new Date(storageData.token_set_at);
        } else {
          this.removeAppToken();
        }
      } catch (ex) {
        this.removeAppToken();
      }
    }
  }

  getAppToken() {
    if (this.hasAppToken()) {
      return this.applicationToken;
    }
    return null;
  }

  setAppToken(newToken) {
    // console.log("Got a token", newToken)
    try {
      const token = validate_jwt_token<IZJWTToken>(newToken);
      const decoded = token.body;
      if (decoded.uid) {
        this.tokenExpiration = decoded.exp;
        this.applicationToken = newToken;
        this.tokenSetAt = new Date();
        const storageData = {
          token: this.applicationToken,
          token_set_at: this.tokenSetAt,
        };
        localStorage.setItem(STORAGE_NAME, JSON.stringify(storageData));
        return true;
      }
      return false;
    } catch (ex) {
      console.error("Error setting token", ex);
      return false;
    }
  }

  isTokenExpired() {
    const currentDate = new Date();
    const currentTime = currentDate.getTime() / 1000;
    if (!this.tokenExpiration) {
      return true;
    }
    return this.tokenExpiration && currentTime > this.tokenExpiration;
  }

  isTokenExpiringSoonOrExpired() {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 1);
    const currentTime = currentDate.getTime() / 1000;
    if (!this.tokenExpiration) {
      return true;
    }
    return this.tokenExpiration && currentTime > this.tokenExpiration;
  }

  hasAppToken() {
    return !!this.applicationToken && !this.isTokenExpired();
  }

  removeAppToken() {
    this.applicationToken = null;
    this.tokenSetAt = null;
    this.tokenExpiration = null;
    localStorage.removeItem(STORAGE_NAME);
    return true;
  }

  // User
  loadCurrentUser() {
    if (window.proto_user === undefined) {
      return;
    }
    try {
      const binaryData = Uint8Array.from(atob(window.proto_user), (c) => c.charCodeAt(0));
      const user = User.fromBinary(binaryData);
      this.setCurrentUserFromProto(user);
    } catch (ex) {
      console.log("Error loading user");
    }
  }

  setCurrentUserFromProto(user: User) {
    // console.log('Set Current User', user);
    if (user == null) {
      this.currentPerson = null;
      // window.user = this.currentPerson;
    } else {
      const newUser = new Person();
      newUser.fillFromCurrentUserProto(user);
      this.currentPerson = newUser;
      // window.user = this.currentPerson;
    }
    this.notifyListeners();
  }

  setCurrentUser(user: UserInfo) {
    // console.log('Set Current User', user);
    if (user == null) {
      this.currentPerson = null;
      // window.user = this.currentPerson;
    } else {
      const newUser = new Person();
      newUser.fillFromCurrentUserEndpoint(user);
      this.currentPerson = newUser;
      // window.user = this.currentPerson;
    }
    this.notifyListeners();
  }

  updateCurrentUser(userData) {
    if (this.currentPerson !== null) {
      this.currentPerson.fillFromCurrentUserEndpoint(userData);
    } else {
      this.setCurrentUser(userData);
    }
  }

  getUserInfo(): Promise<UserInfo> {
    return new Promise<UserInfo>((resolve, reject) => {
      this.endpointGetUserInfo().subscribe({
        next: (endpointUser) => {
          const previousUserId =
            this.currentPerson && this.currentPerson.id
              ? this.currentPerson.id
              : null;
          this.calledUserInfoEndpoint = true;
          if (previousUserId && endpointUser.ID === previousUserId) {
            // Same User, Do Nothing!
            this.updateCurrentUser(endpointUser);
          } else if (previousUserId && endpointUser.ID !== previousUserId) {
            this.setCurrentUser(endpointUser);
            this.emitter.emit(EventChangeUser, endpointUser);
          } else {
            this.setCurrentUser(endpointUser);
            this.emitter.emit(EventLogin, endpointUser);
          }
          this.checkToken()
            .then(() => { })
            .catch(() => { });
          resolve(endpointUser);
        },
        error: (err) => {
          const actualUser = this.currentPerson;
          // Because of ensure, err can be null
          if (err && err.status && err.status === 401) {
            if (actualUser !== null) {
              // User logged out
              this.emitter.emit(EventLogout);
            }
            this.setCurrentUser(null);
            this.removeAppToken();
          }
          this.calledUserInfoEndpoint = true;
          reject(actualUser);
        },
      });
    });
  }

  checkToken() {
    return new Promise((resolve) => {
      if (this.isTokenExpiringSoonOrExpired()) {
        this.fetchToken()
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }

  ensureTokenValidPromise() {
    return new Promise((resolve, reject) => {
      if (!this.hasAppToken() && this.currentPerson === null) {
        reject(NoTokenError);
      } else if (this.isTokenExpiringSoonOrExpired()) {
        this.fetchToken()
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve(true);
      }
    });
  }

  ensureTokenValidObservable() {
    return new Observable((subscriber) => {
      if (!this.hasAppToken() && this.currentPerson === null) {
        subscriber.error(NoTokenError);
      } else if (this.isTokenExpiringSoonOrExpired()) {
        this.fetchToken()
          .then(() => {
            subscriber.next();
            subscriber.complete();
          })
          .catch((err) => {
            subscriber.error(err);
          });
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  fetchTokenLoading: boolean = false;
  fetchToken() {
    if (this.fetchTokenLoading) {
      return new Promise((resolve, reject) => {
        const intChecking = setInterval(() => {
          if (this.fetchTokenLoading === false && this.hasAppToken()) {
            clearInterval(intChecking);
            resolve(this.applicationToken);
          } else if (this.fetchTokenLoading === false && !this.hasAppToken()) {
            clearInterval(intChecking);
            // If we dont have a token, we return a simulated ajax respose null 401
            reject({ status: 401, response: null } as AjaxResponse<null>);
          }
        }, 10);
      });
    }
    this.fetchTokenLoading = true;
    return new Promise((resolve, reject) => {
      this.endpointCreateBearerToken().subscribe({
        next: (response) => {
          if (response.token) {
            this.setAppToken(response.token);
            resolve(response.token);
          } else {
            reject(null);
          }
          this.fetchTokenLoading = false;
        },
        error: (error) => {
          if (error.status && error.status === 401) {
            this.removeAppToken();
          }
          this.fetchTokenLoading = false;
          reject(error);
        },
      });
    });
  }
  firstCallDone = false;
  touchSession() {
    if (!this.firstCallDone) {
      this.firstCallDone = true;
      return;
    }
    if (this.currentPerson && this.currentPerson.id !== "") {
      this.checkToken().then(() => {
        this.endpointTouchSession().subscribe({
          next: () => {
            // Do nothing
            this.emitter.emit(EventTouched);
          },
          error: (errorResponse) => {
            if (errorResponse.status && errorResponse.status === 401) {
              // User logged out
              this.setCurrentUser(null);
              this.emitter.emit(EventLogout);
            } else {
              // Do nothing
            }
          },
        });
      });
    } else {
      this.getUserInfo()
        .then(() => { })
        .catch(() => { });
    }
  }

  isLoggedIn() {
    return this.currentPerson !== null;
  }

  getPerson() {
    return this.currentPerson;
  }

  getEmitter() {
    return this.emitter;
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.endpointLogout().subscribe({
        next: () => {
          this.emitter.emit(EventLogout);
          this.setCurrentUser(null);
          resolve(true);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  ajaxAppendAuthHeaders(requestData: AjaxConfig): AjaxConfig {
    if (this.hasAppToken()) {
      if (!requestData.headers) {
        // eslint-disable-next-line no-param-reassign
        requestData.headers = {} as { token: string };
      }

      // eslint-disable-next-line no-param-reassign
      requestData.headers = Object.assign(requestData.headers, {
        token: this.getAppToken(),
      });
    }
    return requestData;
  }

  ajaxAppendAuthHeadersFetch(requestData: RequestInit): RequestInit {
    if (this.hasAppToken()) {
      if (!requestData.headers) {
        // eslint-disable-next-line no-param-reassign
        requestData.headers = {} as { token: string };
      }
      // eslint-disable-next-line no-param-reassign
      requestData.headers = Object.assign(requestData.headers, {
        token: this.getAppToken(),
      });
    }
    return requestData;
  }

  fetchJSONRequest<T>(url: string, requestData: RequestInit = {}): Promise<T> {
    return this.ensureTokenValidPromise()
      .then(() => {
        const requestDataWithToken =
          this.ajaxAppendAuthHeadersFetch(requestData);
        return fetchJSONRequest<T>(url, requestDataWithToken);
      })
      .catch((err) => {
        if (err.status === 401) {
          this.removeAppToken();
          return this.fetchJSONRequest(url, requestData);
        }
        throw err;
      });
  }
  fetchProtoRequest(url: string, requestData: RequestInit = {}): Promise<ArrayBuffer> {
    return this.ensureTokenValidPromise()
      .then(() => {
        const requestDataWithToken =
          this.ajaxAppendAuthHeadersFetch(requestData);
        return fetchProtoRequest(url, requestDataWithToken);
      })
      .catch((err) => {
        console.error("Error fetching proto", err);
        if (err.status === 401) {
          this.removeAppToken();
          return this.fetchProtoRequest(url, requestData);
        }
        throw err;
      });
  }
  fetchRequest(url: string, requestData: RequestInit = {}): Promise<Response> {
    return this.ensureTokenValidPromise()
      .then(() => {
        const requestDataWithToken =
          this.ajaxAppendAuthHeadersFetch(requestData);
        return fetchRequest(url, requestDataWithToken);
      })
      .catch((err) => {
        if (err.status === 401) {
          this.removeAppToken();
          return this.fetchRequest(url, requestData);
        }
        throw err;
      });
  }

  authenticatedRequest<T>(
    requestData: AjaxConfig,
  ): Observable<AjaxResponse<T>> {
    return this.ensureTokenValidObservable()
      .pipe(
        mergeMap(() => {
          return request<T>(this.ajaxAppendAuthHeaders(requestData));
        }),
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError((err: any, caught) => {
          if (err.status === 401) {
            this.removeAppToken();
            return caught.pipe(
              repeat({ count: 1, delay: 1000 }),
              catchError(() => of(err)),
            );
          }
          throw err;
        }),
      );
  }
  authenticatedGet<T>(url: string): Observable<AjaxResponse<T>> {
    return this.authenticatedRequest({
      url,
      method: "GET",
    });
  }
  authenticatedPost<T>(
    url: string,
    data: unknown,
  ): Observable<AjaxResponse<T>> {
    return this.authenticatedRequest({
      url,
      method: "POST",
      body: data,
    });
  }
  authenticatedPut<T>(url: string, data: unknown): Observable<AjaxResponse<T>> {
    return this.authenticatedRequest({
      url,
      method: "PUT",
      body: data,
    });
  }
  authenticatedDelete<T>(
    url: string,
    data: unknown,
  ): Observable<AjaxResponse<T>> {
    return this.authenticatedRequest({
      url,
      body: data,
      method: "DELETE",
    });
  }

  endpointLogout(): Observable<LogoutResponse> {
    return requestPost<LogoutResponse>(
      `${entitiesEndpointPrefix}/logout`,
      {},
    ).pipe(map((res) => res.response));
  }

  endpointTouchSession(): Observable<TouchResponse> {
    return requestPost<TouchResponse>(
      `${entitiesEndpointPrefix}/touch`,
      {},
    ).pipe(map((res) => res.response));
  }

  endpointCreateBearerToken(): Observable<BearerTokenResponse> {
    return requestPost<BearerTokenResponse>(
      `${entitiesEndpointPrefix}/createBearerToken`,
      {},
    ).pipe(map((res) => res.response));
  }

  endpointGetUserInfo(): Observable<UserInfo> {
    return this.authenticatedGet<UserInfo>(
      `${entitiesEndpointPrefix}/users/me`,
    ).pipe(map((res) => res.response));
  }

  endpointRegister(
    email: string,
    password: string,
    lang: string,
    appcontext?: ExternalApplicationContext,
  ): Observable<RegisterResponse> {
    return requestPost<RegisterResponse>(`${entitiesEndpointPrefix}/register`, {
      email,
      password,
      lang,
      context: appcontext,
    }).pipe(map((res) => res.response));
  }

  endpointGoogleLogin(
    token: string,
    lang: string,
    appcontext?: ExternalApplicationContext,
  ): Observable<GoogleLogin> {
    return requestPost<GoogleLogin>(`${entitiesEndpointPrefix}/google/login`, {
      token,
      lang,
      context: appcontext,
    }).pipe(map((res) => res.response));
  }

  endpointLogin(
    email: string,
    password: string,
    remember_me = false,
    with_authorization = false,
  ): Observable<LoginResponse> {
    return request<LoginResponse>({
      url: `${entitiesEndpointPrefix}/login`,
      queryParams: {
        remember_me,
        with_authorization,
      },
      headers: {
        Authorization: `Basic ${window.btoa(`${email}:${password}`)}`,
      },
      method: "POST",
    }).pipe(map((res) => res.response));
  }

  userWebAuthnUnauthLogin(): Observable<{
    sessionID: string;
    response: WebAuthnUnauthLoginResponse;
  }> {
    return request<WebAuthnUnauthLoginResponse>({
      url: `${entitiesEndpointPrefix}/webauth/login`,
      method: "POST",
      body: {},
    }).pipe(
      map((res) => {
        return {
          sessionID: res.responseHeaders["x-session-id"],
          response: res.response,
        };
      }),
    );
  }

  userWebAuthnLoginFinish(
    session_id: string,
    registration: AuthenticationResponseJSON,
  ): Observable<LoginResponse> {
    return request<LoginResponse>({
      method: "PUT",
      url: `${entitiesEndpointPrefix}/webauth/login`,
      body: registration,
      headers: {
        "X-Session-ID": session_id,
      },
    }).pipe(
      map((res) => {
        return res.response;
      }),
    );
  }

  endpointUserActivate(token: string): Observable<ActivateResponse> {
    return requestPost<ActivateResponse>(`${entitiesEndpointPrefix}/activate`, {
      token,
    }).pipe(map((res) => res.response));
  }

  endpointUserForgotPassword(
    email: string,
    context?: ExternalApplicationContext,
  ): Observable<UserForgotPasswordResponse> {
    return requestPost<UserForgotPasswordResponse>(
      `${entitiesEndpointPrefix}/forgot_password`,
      {
        email,
        context,
      },
    ).pipe(map((res) => res.response));
  }

  endpointUserResendActivation(
    email: string,
    context?: ExternalApplicationContext,
  ): Observable<UserResendActivationResponse> {
    return requestPost<UserResendActivationResponse>(
      `${entitiesEndpointPrefix}/resend_activation`,
      {
        email,
        context,
      },
    ).pipe(map((res) => res.response));
  }

  endpointUserChangePasswordToken(
    token: string,
    password: string,
  ): Observable<UserChangePasswordWithTokenResponse> {
    return requestPost<UserChangePasswordWithTokenResponse>(
      `${entitiesEndpointPrefix}/change_password_with_token`,
      {
        token,
        password,
      },
    ).pipe(map((res) => res.response));
  }

  endpointEmailActivate(token: string): Observable<UserEmailActivateResponse> {
    return requestPost<UserEmailActivateResponse>(
      `${entitiesEndpointPrefix}/email_activate`,
      {
        token,
      },
    ).pipe(map((res) => res.response));
  }

  endpointGetSession(): Promise<never> {
    return fetch(`${entitiesEndpointPrefix}/users/session`, {
      credentials: "include",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
    }).then((res) => {
      return res.json() as Promise<never>;
    });
  }

  waitForUserInfoEndpointCalled(resolve, reject) {
    if (this.calledUserInfoEndpoint) {
      if (this.isLoggedIn()) {
        resolve({
          success: true,
          user: this.currentPerson,
          emitter: this.emitter,
        });
      } else {
        reject({
          success: false,
          isError: true,
          emitter: this.emitter,
          error: {
            name: "UserNotLoggedIn",
            code: 1000,
          },
        });
      }
    } else {
      setTimeout(() => {
        this.waitForUserInfoEndpointCalled(resolve, reject);
      }, 100);
    }
  }

  ensure() {
    return new Promise((resolve, reject) => {
      this.waitForUserInfoEndpointCalled(resolve, reject);
    });
  }

  ensureAppToken() {
    return new Promise((resolve, reject) => {
      if (this.hasAppToken()) {
        resolve(true);
      } else {
        let count = 0;
        const intervalEnsure = setInterval(() => {
          if (this.hasAppToken()) {
            resolve(true);
            clearInterval(intervalEnsure);
          }
          if (count > 10) {
            clearInterval(intervalEnsure);
            reject(new Error("Unable to get token"));
          }
          count += 1;
        }, 100);
      }
    });
  }

  //Events
  onLogout(callback: () => void) {
    this.emitter.on(EventLogout, callback);
    return {
      unsubscribe: () => {
        this.emitter.off(EventLogout, callback);
      }
    }

  }
  onLogin(callback: () => void) {
    this.emitter.on(EventLogin, callback);
    return {
      unsubscribe: () => {
        this.emitter.off(EventLogin, callback);
      }
    }
  }
  onChangeUser(callback: () => void) {
    this.emitter.on(EventChangeUser, callback);
    return {
      unsubscribe: () => {
        this.emitter.off(EventChangeUser, callback);
      }
    }
  }
}

interface LogoutResponse {
  success: boolean;
}
interface TouchResponse {
  success: boolean;
}
interface BearerTokenResponse {
  token: string;
}
export interface UserInfo {
  ID: string;
  Name: string;
  Status: number;
  Shortname: string;
  Birthday: string;
  PasswordChangedOn: string;
  Lang: string;
  Email: string;
  EmailValid: boolean;
  Emails?: UserEmail[];
  OTPAddedAt?: string;
  OTPType: number;
  WebauthnCertificatesAdded: boolean;
}
interface RegisterResponse {
  success: boolean;
  // eslint-disable-next-line camelcase
  user_id: string;
}
interface GoogleLogin {
  success: boolean;
  // eslint-disable-next-line camelcase
  account_valid: boolean;
  // eslint-disable-next-line camelcase
  email_valid: boolean;
  token: string;
}
interface LoginResponse {
  success: boolean;
  // eslint-disable-next-line camelcase
  account_valid: boolean;
  // eslint-disable-next-line camelcase
  email_valid: boolean;
  token: string;
  authorization_token: string;
  requires_authorization: boolean;
}
interface ActivateResponse {
  success: boolean;
  context?: ExternalApplicationContext;
}
interface UserForgotPasswordResponse {
  success: boolean;
}
interface UserResendActivationResponse {
  success: boolean;
}
interface UserChangePasswordWithTokenResponse {
  success: boolean;
  context?: ExternalApplicationContext;
}
interface UserEmailActivateResponse {
  success: boolean;
  context?: ExternalApplicationContext;
}

const currentUser = new CurrentUser();
window.CurrentUser = currentUser;
export default currentUser;
