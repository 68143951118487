import { createBrowserHistory } from "history";
import Application from "./application";
import currentUser from "./CurrentUser";

const history = createBrowserHistory({ window });

const app = new Application(history.location as unknown as Location);
history.listen((event) => {
  app.emitURLChanged(event.location.pathname);
});

class GeneralContext {
  application: Application = app;

  history = history;

  currentUser = currentUser;

  setApplication(newApp: Application) {
    this.application = newApp;
  }
  push(href: string, state?: any) {
    let newhref = context.application.createURLWithContext(href);
    this.history.push(
      {
        pathname: newhref.href,
      },
      state,
    );
  }
  replace(href: string, state?: any) {
    let newhref = context.application.createURLWithContext(href);
    this.history.replace(
      {
        pathname: newhref.href,
      },
      state,
    );
  }
}
const context = new GeneralContext();
window["_context"] = context;
export default context;
