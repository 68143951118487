import "./currentScript.polyfill";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import currentUser from "./context/CurrentUser";
require("./modules/error/allErrors");
import { appregistry, appinit } from "./modules/appbootstrap";



import * as navLoader from "./site/layouts/header.component_loader";
appregistry.Add("custom_nav", (node: Node) => {
  navLoader.load(node);
});

appinit();