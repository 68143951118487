

export class Registry {
    private registry: { [key: string]: (node: Node) => void } = {};

    public Add(name: string, loader: (node: Node) => void): void {
        this.registry[name] = loader;
    }

    public Execute(name: string, node: Node): void {
        if (this.registry[name]) {
            this.registry[name](node);
        }
    }

    public Exists(name: string): boolean {
        return !!this.registry[name];
    }
}

