import { appregistry } from "./";

function isVisible(elem) {
    return !!(
        elem.offsetWidth ||
        elem.offsetHeight ||
        elem.getClientRects().length
    );
}

function loadFromAttribute() {
    const elementList = document.querySelectorAll("[iz-component]");
    for (let i = 0; i < elementList.length; i += 1) {
        const element = elementList[i];
        const componentName = element.getAttribute("iz-component");
        const loaded = element.getAttribute("_iz_loaded");
        if (
            appregistry.Exists(componentName) &&
            loaded !== "true" &&
            isVisible(element)
        ) {
            element.setAttribute("_iz_loaded", "true");
            appregistry.Execute(componentName, element);
        }
    }
}

function loadFromTag() {
    const elementList = document.querySelectorAll("iz-component");
    for (let i = 0; i < elementList.length; i += 1) {
        const element = elementList[i];
        const componentName = element.getAttribute("name");
        const loaded = element.getAttribute("_iz_loaded");
        if (
            appregistry.Exists(componentName) &&
            loaded !== "true" &&
            isVisible(element)
        ) {
            element.setAttribute("_iz_loaded", "true");
            appregistry.Execute(componentName, element);
        }
    }
}


// Returns true if it is a DOM node
function isNode(o) {
    return typeof Node === "object"
        ? o instanceof Node
        : o &&
        typeof o === "object" &&
        typeof o.nodeType === "number" &&
        typeof o.nodeName === "string";
}
window.loadAppOn = (app: string, node: Node) => {
    if (appregistry.Exists(app) && isNode(node)) {
        appregistry.Execute(app, node);
    } else if (!appregistry.Exists(app)) {
        // eslint-disable-next-line no-console
        console.error(`No app ${app}`);
    } else {
        // eslint-disable-next-line no-console
        console.error("Bad node");
    }
};
window.reloadComponents = () => {
    loadFromTag();
    loadFromAttribute();
};

export function appinit() {
    document.documentElement.className = document.documentElement.className.replace(
        "no-js",
        "js",
    );
    loadFromTag();
    loadFromAttribute();
}